































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import { MeetingTypes } from "@/core/models";
import { textRequired, linkRequired } from "@/core/utils/formUtils";
import { UsersSelect, EmailsInput, DateTimePicker } from "@/components/common";

@Component({ components: { UsersSelect, EmailsInput, DateTimePicker } })
export default class AddMeetingButton extends Vue {
  @Getter("groups/usersManaged") users!: string[];
  @Getter("meetings/addMeetingLoading") addMeetingLoading!: boolean;
  @Action("meetings/addMeeting") addMeetingAction!: Function;

  textRules = textRequired;
  linkRules = linkRequired;

  formValid = false;
  addDialog = false;
  types = MeetingTypes.getValues();
  selectedType = MeetingTypes.Blizz;
  get isCustom() {
    return this.selectedType === MeetingTypes.Custom;
  }

  // Meeting specific
  dateError = "";
  customLink = "";
  meetingSubject = "";
  meetingStart = new Date(Date.now());
  meetingEnd = new Date(Date.now() + 60 * 60 * 1000);

  // Password specific
  meetingPass = "";
  showPass = false;
  hasPassword = false;

  // Adding users
  nextUser = "";
  invitedUsers: string[] = [];
  additionalUsers: string[] = [];

  get canSubmit() {
    if (this.meetingStart >= this.meetingEnd) {
      this.dateError = "Meeting end date has to be after its start";
      return false;
    } else this.dateError = "";

    if (this.hasPassword && !this.meetingPass) return false;

    return (
      this.formValid &&
      this.invitedUsers.length + this.additionalUsers.length > 0
    );
  }

  addMeeting() {
    if (this.addMeetingLoading || !this.formValid || !this.canSubmit) return;
    if (this.nextUser) this.additionalUsers.push(this.nextUser);
    let data = {
      type: this.selectedType,
      subject: this.meetingSubject,
      startDate: this.meetingStart,
      endDate: this.meetingEnd,
      password: this.meetingPass,
      meetingLink: this.customLink,
      invitedUsers: this.invitedUsers.concat(this.additionalUsers),
    };
    this.addMeetingAction(data);
    this.resetDialog();
  }

  resetDialog() {
    this.addDialog = false;
    // this.meetingSubject = "";
    // this.nextUser = "";
    // this.meetingPass = "";
    // this.hasPassword = false;
    // this.showPass = false;
    // this.customLink = "";
    // this.dateError = "";
    // this.invitedUsers = [];
    // this.additionalUsers = [];
  }

  @Watch("meetingStart", { deep: true })
  startDateChanged() {
    this.meetingEnd = new Date(this.meetingStart.getTime() + 60 * 60 * 1000);
  }
}
