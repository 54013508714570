































































































import Vue from "vue";
import { Getter } from "vuex-class";
import { Meeting } from "@/core/models";
import { Component } from "vue-property-decorator";

import { AddMeetingButton, MeetingMenu } from "./components";
import { InformationTooltip } from "@/components/common";

@Component({
  components: {
    MeetingMenu,
    AddMeetingButton,
    InformationTooltip,
  },
})
export default class Meetings extends Vue {
  @Getter("meetingsError") error!: boolean;
  @Getter("meetingsLoading") loading!: boolean;
  @Getter("groups/canManage") canManage!: boolean;
  @Getter("meetings/getMeetings") meetings!: Meeting[];

  hasExpired(meeting: Meeting) {
    const now = new Date(Date.now());
    return +now > +meeting.CreatedAt;
  }
  gotoMeeting(meeting: Meeting) {
    window.open(meeting.MeetingLink, "_blank");
  }
}
