import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-12",attrs:{"id":"meetings-page"}},[_c(VOverlay,{staticClass:"d-flex align-center justify-center",attrs:{"absolute":"","value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),(!_vm.loading && _vm.error)?_c(VContainer,[(_vm.error)?_c(VRow,[_c(VCard,{staticClass:"px-4 py-2 text-center font-weight-medium",attrs:{"dark":"","color":"error"}},[_c('span',[_vm._v(_vm._s(_vm.error))])])],1):_vm._e()],1):_vm._e(),(!_vm.loading)?_c(VContainer,[_c(VRow,{staticClass:"d-flex justify-space-between align-center my-12"},[_c('span',{staticClass:"display-1 font-weight-bold"},[_vm._v(_vm._s(_vm.$t("meetingsSection.yourMeetings")))]),_c('AddMeetingButton')],1),_c(VCard,{staticClass:"mb-12",attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"d-flex"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("meetingsSection.allMeetingsCard.allMeetings")))]),_c(VSpacer)],1),(_vm.meetings.length > 0)?_c(VCardText,[_c(VContainer,[_c(VSlideYTransition,{attrs:{"group":""}},_vm._l((_vm.meetings),function(meeting,i){return _c(VListItem,{key:i,class:{
                accent: _vm.hasExpired(meeting),
                'mb-4': i !== _vm.meetings.length - 1,
              },attrs:{"disabled":_vm.hasExpired(meeting)}},[_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(meeting.Subject || _vm.$t("meetingsSection.allMeetingsCard.noSubject"))+" ")]),_c(VListItemSubtitle,{staticStyle:{"font-size":"16px"}},[_c('span',[_vm._v(_vm._s(_vm._f("format")(meeting.StartDate)))])]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.$t("meet.created"))+" "+_vm._s(meeting.Creator.Username)+" ")]),(_vm.hasExpired(meeting))?_c(VListItemSubtitle,{staticClass:"mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t("meet.expired")))])]):_vm._e()],1),_c(VListItemAction,[_c(VBtn,{attrs:{"elevation":"0","color":"primary","disabled":_vm.hasExpired(meeting)},on:{"click":function($event){$event.stopPropagation();return _vm.gotoMeeting(meeting)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("meetingsSection.allMeetingsCard.joinMeeting")))])])],1),_c(VListItemAction,[_c('MeetingMenu',{attrs:{"meeting":meeting}})],1)],1)}),1)],1)],1):_c(VCardText,[_vm._v(_vm._s(_vm.$t("meetingsSection.allMeetingsCard.noMeetings")))])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }