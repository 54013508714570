export const textRequired = [
  (text: string) => !!text || "This field is required",
  (text: string) => text.length >= 2 || "Too short",
];

const linkRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
export const linkRequired = [
  (link: string) => !!link || "This field is required",
  (link: string) => linkRegex.test(link) || "Invalid link",
];
