





































































import Vue from "vue";
import { Meeting } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class MeetingMenu extends Vue {
  @Prop({ default: () => {} }) meeting!: Meeting;

  @Getter("meetings/editedMeetingID") editedMeetingID!: string;
  @Getter("meetings/editMeetingLoading") editMeetingLoading!: string;
  @Getter("meetings/removedMeetingID") removedMeetingID!: string;
  @Getter("meetings/removeMeetingLoading") removeMeetingLoading!: string;

  @Action("meetings/editMeeting") editMeetingAction!: Function;
  @Action("meetings/removeMeeting") removeMeetingAction!: Function;

  subjectEdit = "";
  passwordEdit = "";
  editDialog = false;
  isPinnedEdit = false;

  // Edit meeting
  @Watch("editDialog")
  toggleEdit() {}
  editMeeting() {
    console.log("Not done yet");
    this.editDialog = false;
    return;
  }

  // Delete meeting
  deletePost() {
    if (this.removeMeetingLoading) return;
    this.removeMeetingAction(this.meeting.ID);
  }
}
